import { GroupedVariants } from "../types/ProductTypes";
import { ProductVariant } from "./productmgmtHelpers";

interface FilteredResult {
  category: string;
  variants: Variant[];
}
interface Category {
  category: string;
  _count: { id: number };
  variants: Variant[];
}
interface Variant {
  id: number;
  name: string;
  variantCode: string;
  createdAt: string;
  updatedAt: string;
  active: boolean;
}


export function splitSKUWithPrefix(sku: string, limit): string[] {
    const numericPart: string = sku.slice(limit?.length);
    const result: string[] = (numericPart.match(/.{1,4}/g) || []).map(segment => 'V' + segment);
    return result;
  }

  export function splitSKUWithPrefixOptions(skus: string[], limit): string[][] {
    return skus.map(sku => {
        const numericPart: string = sku.slice(limit?.length);
        const result: string[] = (numericPart.match(/.{1,4}/g) || []).map(segment => 'V' + segment);
        return result;
    });
}



  export function filterVariants(variantCodes: string[], variantsData: Category[]): string[] {
    const result: string[] = [];

    if (variantCodes.length > 0 && variantsData && variantsData.length > 0) {
        const variantCodesSet = new Set(variantCodes);

        for (const categoryData of variantsData) {
            if (categoryData.variants && categoryData.variants.length > 0) {
                const filteredVariants = categoryData.variants
                    .filter((variant) => variantCodesSet.has(variant.variantCode))
                    .map((variant) => variant.name);

                if (filteredVariants.length > 0) {
                    result.push(...filteredVariants);
                }
            }
        }
    }
    return result;
}
export function skuToNameConverter(product_groups: GroupedVariants[], sku_code: String, base_sku: String){
  var sku_splited = splitSKUWithPrefix(sku_code as string, base_sku)
  // lets then search the sku in the product group
  var sku_string = ""
  sku_splited.forEach((sku) => {
      for(const [key, value] of Object.entries(product_groups!)){
          var filtered_result = value.filter((val) => val["code"] === sku);
          if(filtered_result.length > 0) {
                sku_string = sku_string + " " + key + ": " + filtered_result[0]["value"]
          }
      }
  })
  return sku_string.trim()
}


