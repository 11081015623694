import {configureStore} from '@reduxjs/toolkit'
import productReducer from '../redux/productReducer'
import editProductReducer from '../redux/editProductReducer'
const store = configureStore({
       reducer: {
          product: productReducer,
          editProduct: editProductReducer
       }
})
export default store
export type RootState = ReturnType<typeof store.getState>
export type appDispatch = typeof store.dispatch;