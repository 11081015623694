// Go to components/MenuItems to get the sidebar links
export enum RouteEnums {
  Dashboard = "/",
  Login = "/login",
  NotFound = "/404",

  // Categories routes
  Categories = "/categories",
  CreateCategory = "/categories/create",
  EditCategory = "/categories/:id/edit",

  // Tags routes
  Tags = "/tags",
  CreateTag = "/tags/create",
  EditTag = "/tags/:id/edit",

  // Users route
  Users = "/users",
  CreateUser = "/users/create",
  EditUser = "/users/:id/edit",

  // Post routers
  CreatePost = "/contents/create",
  GetAllPosts = "/contents",
  PreviewPosts = "/contents/:id/preview",
  EditPostDetails = "/contents/:id/edit",
  PostPriority = "/content-priority",
  CreatePostPriority = "/content-priority/create",
  EditPostPriority = "/content-priority/:id/edit",
  PostCommentApproval = "/comment-approval",

  // Admin utilities
  AdminUtilities = "/utilities",

  // Role routes
  Roles = "/utilities/roles",
  CreateRoles = "/utilities/roles/create",
  RoleEditRoute = "/utilities/roles/:id/edit",
  AccessRoutesOfRole = "/utilities/roles/:id/access-routes",
  AppSetting = "/utilities/settings",

  // SEO routes
  SEOContentList = "/seo-contents",
  SEOContentEdit = "/seo-contents/:id/edit",
  SEORedirectList = "/seo-redirects",
  CreateSEORedirection = "/seo-redirects/create",
  SEORedirectContentEdit = "/seo-redirects/:id/edit",

  // Image gallery
  GalleryList = "/gallery",
  UploadMedia = "/gallery/upload",
  UpdateMediaFile = "/gallery/:id/edit",

  // get reports added by eyoel
  Reports = "/reports",
  // apointment routes
  Appointments = "/appointments",
  // telehealth routes
  TeleHealth = "/services",
  TelehealthCreate = "/services/create",
  PRODUCTMANAGEMENT = "/product-management",
  PRODUCTCREATE = "/product-management/create",
  PRODUCTEDIT = "/product-management/:id/edit",
  VARIANTS = "/vacategories/variant/:id",
  VARIANTS_CREATE = "/vacategories/variant/categories/create",
  VARIANTS_EDIT = "/variantcategories/:id/edit",
  VARIANT_CATEGORIES = "/vacategories",
  CART = "/cart",
  CART_DETAILS = "/cart/details",
  ORDER_MANAGEMENT = "/order-management",
  ORDER_DETAILS = "/order-management/:id/details",

  //prod cate and subcate
  PRODUCT_CATEGORIES = "/product-categories",
  PRODUCT_SUBCATEGORIES = "/product-subcategories",
  PRODUCT_CATEGORIES_EDIT = "/product-categories/:id/edit",
  PRODUCT_CATEGORIES_CREATE = "/product-categories/create",
  PRODUCT_SUBCATEGORIES_EDIT = "/product-subcategories/:id/edit",
  PRODUCT_SUBCATEGORIES_CREATE = "/product-subcategories/create",
  //banners
  BANNERS = "/banners",
  BANNERS_CREATE = "/banners/create",
  BANNERS_EDIT = "/banners/:id/edit",
  //approvals
  ONBOARDING_APPROVAL = "/onboarding-approval",
  UPDATE_APPROVAL = "/update-approval",
  WITHDRAW_APPROVAL = "/withdraw-approval",
  STORE = "/store",
  STORE_CREATE = "/store/create",
  STORE_EDIT = "/store/:id/edit",
  FREE_RESOURCE = "/free-resource",
  FREE_RESOURCE_CREATE = "/free-resource/create",
  FREE_RESOURCE_EDIT = "/free-resource/:id/edit",
  DeliveryCreateForm = "/utilities/settings/create",
  DeliveryUpdateForm = "/utilities/settings/edit/:id",
  PAYMENT_METHOD_CREATE = "/utilities/settings/service/create",
  PRODUCT_REVIEW = "/product-reviews",
  FAQ = "/faq",
  FAQ_CREATE = "/faq/create",
  FAQ_EDIT = "/faq/:id/edit",
  UnauthorizedPage = "/unauthorized401",
}
