import { RouteEnums } from "../routes/routes.type";

export const Config = {
  BaseUrl:
    process.env.REACT_APP_ENV === "dev"
      ? (process.env.REACT_APP_API_DEV as string)
      : (process.env.REACT_APP_API_STAGING as string),

  BrandName: "supertal",
  Logo: "/images/logo.png",
  LoginImage: "/images/login-image.webp",
  LogoIcon: "/images/logo.png",
  LogoIco: "/favicon.ico",
  BackGroud: "./images/telehealth2.png",
  YhLogo: "./images/yhlogo.svg",
  BucketLink:
    process.env.REACT_APP_ENV === "dev"
      ? (process.env.REACT_APP_BUCKET_LINK_DEV as string)
      : (process.env.REACT_APP_BUCKET_LINK_STAGING as string),
  BrokenImage: "/images/dummy-image.svg",
  BrokenImageBanner: "/images/dummy-image-banner.svg",
  DefaultAvatar: "/images/default-avatar.webp",

  accessKeyId: "AKIAXCTWOIVEJNQ7OJHN",
  secretAccessKey: "l68UUrBTQeGl8QWYUCO3TA3T+U3dilD00YYoMOtU",
  bucketName: "supertal-bucket",
};

export const UnAuthRoutes: string[] = [RouteEnums.Login, RouteEnums.NotFound];
