import React from 'react'
import { Suspense, lazy } from 'react'
import { Provider } from 'react-redux'
import store from '../store/store'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Loader from '../components/Loader/Index'
import { RouteEnums } from './routes.type'
import capitalLetterWord from '../utils/capitalLetterWord'
const PostPriority = lazy(() => import('../pages/Posts/PostPriority/Index'))
const CreatePostPriority = lazy(
	() => import('../pages/Posts/PostPriority/CreatePostPriority/Index')
)
const EditPostPriority = lazy(
	() => import('../pages/Posts/PostPriority/EditPostPriority/Index')
)
const PreviewPost = lazy(() => import('../pages/Posts/PreviewPost/Index'))
const ServiceFormPage = lazy(() => import('../pages/Telehealth/ServiceForm'))
const InvoiceOrder = lazy(() => import('../pages/OrderManagement/InvoiceOrder'))
const UpdateApproval = lazy(() => import('../pages/Telehealth/UpdateApproval'))
const UnauthorizedPage = lazy(
	() => import('../pages/Unauthorized/UnauthorizedPage')
)
const UploadMedia = lazy(() => import('../pages/Gallery/UploadMedia/Index'))
const MediaGallery = lazy(() => import('../pages/Gallery/Index'))
const VariantForm = lazy(() => import('../pages/ProductManagement/VariantForm'))
const UpdateMediaFile = lazy(
	() => import('../pages/Gallery/EditMediafile/EditMediaFile')
)
const CartManagement = lazy(() => import('../pages/CartManagment/index'))
const TeleHealth = lazy(() => import('../pages/Telehealth'))
const ProductManagement = lazy(() => import('../pages/ProductManagement'))
const ProductFormPage = lazy(
	() => import('../pages/ProductManagement/ProductForm')
)
const EditSEOContent = lazy(
	() => import('../pages/SEO/SEOContent/EditSEOContent/Index')
)
const Variant = lazy(() =>
	import('../pages/ProductManagement/Variant').then((module) => ({
		default: module.default as React.ComponentType<any>
	}))
)
const EditSeoUrlRedirection = lazy(
	() => import('../pages/SEO/SEORedirect/EditUrlRedirection/Index')
)
const CartProductDetail = lazy(
	() => import('../pages/CartManagment/CartProductDetailPage ')
)
const CategoryVariant = lazy(() =>
	import('../pages/ProductManagement/VariantCategory').then((module) => ({
		default: module.default as React.ComponentType<any>
	}))
)

const ProductCategory = lazy(
	() => import('../pages/ProductManagement/ProductCategory/ProductCategory')
)
const SubCategory = lazy(
	() => import('../pages/ProductManagement/ProductSubCategory/SubCategory')
)
const SubcategoryFormCreateEdit = lazy(
	() => import('../pages/ProductManagement/ProductSubCategory/SubCategoryForm')
)
const OrderManagement = lazy(() => import('../pages/OrderManagement/index'))
const OrdermanagementPage = lazy(() => import('../pages/OrderManagement'))
const EditVariant = lazy(() => import('../pages/ProductManagement/VariantForm'))
const CreateSeoRedirection = lazy(
	() => import('../pages/SEO/SEORedirect/CreateSeoRedirection/Index')
)
const SeoContentList = lazy(() => import('../pages/SEO/SEOContent/Index'))
const SeoRedirectList = lazy(() => import('../pages/SEO/SEORedirect/Index'))

const EditRole = lazy(() => import('../pages/Roles/Update/Index'))
const Users = lazy(() => import('../pages/Users/Index'))
const CreateUser = lazy(() => import('../pages/Users/CreateUser/Index'))
const EditUser = lazy(() => import('../pages/Users/EditUser/Index'))

const EditPost = lazy(() => import('../pages/Posts/EditPost/Index'))
const AdminLayout = lazy(() => import('../layouts/Admin/Index'))
const RoleDetails = lazy(() => import('../pages/Roles/Details/Index'))
const CreateRole = lazy(() => import('../pages/Roles/Create/Index'))
const Utilities = lazy(() => import('../pages/Utilities/Index'))
const Roles = lazy(() => import('../pages/Roles/Index'))

const Categories = lazy(() => import('../pages/Categories/Index'))
const CreateCategory = lazy(
	() => import('../pages/Categories/CreateCategory/Index')
)
const EditCategory = lazy(
	() => import('../pages/Categories/EditCategory/Index')
)
const ProductCategoryFormCreateEdit = lazy(
	() => import('../pages/ProductManagement/ProductCategory/ProductCategoryForm')
)
const Tags = lazy(() => import('../pages/Tags/Index'))
const CreateTag = lazy(() => import('../pages/Tags/CreateTag/Index'))
const EditTag = lazy(() => import('../pages/Tags/EditTag/Index'))

const CreatePost = lazy(() => import('../pages/Posts/CreatePost/Index'))
const Posts = lazy(() => import('../pages/Posts/Index'))
const PostComments = lazy(() => import('../pages/Posts/Comments/Index'))
const NotFound = lazy(() => import('../components/NotFound/Index'))
const ProtectedRoute = lazy(() => import('../layouts/Protected/Index'))
const Login = lazy(() => import('../pages/Auth/Login/Index'))
const Dashboard = lazy(() => import('../pages/Dashboard/Index'))
const Reports = lazy(() => import('../pages/Reports/Index'))
const EditProduct = lazy(
	() => import('../pages/ProductManagement/ProductEditForm')
)
const Banner = lazy(() => import('../pages/BannerManagment/index'))
const Appointments = lazy(() => import('../pages/Appointments/Index'))
const BannerFormCreateEdit = lazy(
	() => import('../components/Banner/BannerForm')
)
const onboardingApproval = lazy(
	() => import('../pages/Telehealth/OnboardingApproval')
)
const WithdrawApproval = lazy(() => import('../pages/Telehealth/WithdrawReq'))
const Store = lazy(() => import('../pages/ProductManagement/Store/Store'))
const StoreFormCreateEdit = lazy(() => import('../components/Store/StoreForm'))
const FreeResources = lazy(
	() => import('../pages/Posts/FreeResources/FreeResource')
)
const FreeResourceFormCreateEdit = lazy(
	() => import('../pages/Posts/FreeResources/FreeResourceForm')
)
const Settings = lazy(() => import('../pages/Settings/Settings'))
const DeliveryCreateForm = lazy(
	() => import('../pages/Settings/Delivery/DeliveryCreateForm')
)
const DeliveryEditForm = lazy(
	() => import('../pages/Settings/Delivery/DeliveryUpdateForm')
)
const PaymentServiceCreateForm = lazy(
	() => import('../pages/Settings/Payment/PaymentServiceCreateForm')
)
const ProductReviews = lazy(
	() => import('../pages/ProductReviews/Productreviews')
)
const Faq = lazy(() => import('../pages/Faq/Index'))
const FaqFormCreateEdit = lazy(
	() => import('../components/Faq/FaqFormCreateEdit')
)
function App() {
	const { pathname } = useLocation()

	return (
		<Provider store={store}>
			<HelmetProvider>
				<Helmet>
					<title>
						{/* {Config.BrandName.toUpperCase()} */}
						{pathname === '/'
							? 'Dashboard'
							: '' +
							  pathname
									.split('/')
									.filter((title: string) => title !== '')
									.map((title: string) => capitalLetterWord(title))
									.join(' | ')}
					</title>
				</Helmet>
				<AdminLayout>
					<Routes>
						{/* Login */}
						<Route
							path={RouteEnums.Login}
							element={
								<Suspense fallback={<Loader />}>
									<Login />
								</Suspense>
							}
						/>
						{/* Dashboard */}
						<Route
							path={RouteEnums.Dashboard}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={Dashboard} />
								</Suspense>
							}
						/>
						<Route
							path={RouteEnums.UnauthorizedPage}
							element={
								<Suspense fallback={<Loader />}>
									<UnauthorizedPage />
								</Suspense>
							}
						/>
						{/* settings */}
						<Route
							path={RouteEnums.AppSetting}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={Settings} />
								</Suspense>
							}
						/>
						<Route
							path={RouteEnums.FAQ_CREATE}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={FaqFormCreateEdit} />
								</Suspense>
							}
						/>
						<Route
							path={RouteEnums.FAQ_EDIT}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={FaqFormCreateEdit} />
								</Suspense>
							}
						/>
						{/* DeliveryCreateForm */}
						<Route
							path={RouteEnums.DeliveryCreateForm}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={DeliveryCreateForm} />
								</Suspense>
							}
						/>
						{/* DeliveryEditForm */}

						<Route
							path={RouteEnums.DeliveryUpdateForm}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={DeliveryEditForm} />
								</Suspense>
							}
						/>
						<Route
							path={RouteEnums.PAYMENT_METHOD_CREATE}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={PaymentServiceCreateForm} />
								</Suspense>
							}
						/>

						<Route
							path={RouteEnums.FAQ}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={Faq} />
								</Suspense>
							}
						/>

						{/* Reports */}
						<Route
							path={RouteEnums.Reports}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={Reports} />
								</Suspense>
							}
						/>
						{/* Banner */}
						<Route
							path={RouteEnums.BANNERS}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={Banner} />
								</Suspense>
							}
						/>
						{/* Banner Form create */}
						<Route
							path={RouteEnums.BANNERS_CREATE}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={BannerFormCreateEdit} />
								</Suspense>
							}
						/>
						<Route
							path={RouteEnums.STORE}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={Store} />
								</Suspense>
							}
						/>
						{/* ProductReviews */}
						<Route
							path={RouteEnums.PRODUCT_REVIEW}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={ProductReviews} />
								</Suspense>
							}
						/>
						<Route
							path={RouteEnums.STORE_CREATE}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={StoreFormCreateEdit} />
								</Suspense>
							}
						/>
						<Route
							path={RouteEnums.STORE_EDIT}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={StoreFormCreateEdit} />
								</Suspense>
							}
						/>
						{/* FreeResources */}
						<Route
							path={RouteEnums.FREE_RESOURCE}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={FreeResources} />
								</Suspense>
							}
						/>
						{/* FreeResourceFormCreateEdit */}
						<Route
							path={RouteEnums.FREE_RESOURCE_CREATE}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={FreeResourceFormCreateEdit} />
								</Suspense>
							}
						/>
						{/* FreeResourceFormCreateEdit */}
						<Route
							path={RouteEnums.FREE_RESOURCE_EDIT}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={FreeResourceFormCreateEdit} />
								</Suspense>
							}
						/>
						{/* Banners Form Edit */}
						<Route
							path={RouteEnums.BANNERS_EDIT}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={BannerFormCreateEdit} />
								</Suspense>
							}
						/>
						{/* TeleHealth */}
						<Route
							path={RouteEnums.TeleHealth}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={TeleHealth} />
								</Suspense>
							}
						/>
						{/* withdraw */}
						<Route
							path={RouteEnums.WITHDRAW_APPROVAL}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={WithdrawApproval} />
								</Suspense>
							}
						/>
						{/* Product Management */}
						<Route
							path={RouteEnums.PRODUCTMANAGEMENT}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={ProductManagement} />
								</Suspense>
							}
						/>
						{/* Product Category */}
						<Route
							path={RouteEnums.PRODUCT_CATEGORIES}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={ProductCategory} />
								</Suspense>
							}
						/>
						{/* Sub Category */}
						<Route
							path={RouteEnums.PRODUCT_SUBCATEGORIES}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={SubCategory} />
								</Suspense>
							}
						/>
						{/* Sub Category Form create */}
						<Route
							path={RouteEnums.PRODUCT_SUBCATEGORIES_CREATE}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={SubcategoryFormCreateEdit} />
								</Suspense>
							}
						/>
						{/* Sub Category Form Edit */}
						<Route
							path={RouteEnums.PRODUCT_SUBCATEGORIES_EDIT}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={SubcategoryFormCreateEdit} />
								</Suspense>
							}
						/>
						{/* Cart Management */}
						<Route
							path={RouteEnums.CART}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={CartManagement} />
								</Suspense>
							}
						/>
						{/* ProductCategoryForm create */}
						<Route
							path={RouteEnums.PRODUCT_CATEGORIES_CREATE}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={ProductCategoryFormCreateEdit} />
								</Suspense>
							}
						/>
						{/* ProductCategoryForm Edit */}
						<Route
							path={RouteEnums.PRODUCT_CATEGORIES_EDIT}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={ProductCategoryFormCreateEdit} />
								</Suspense>
							}
						/>
						{/* category variant */}
						<Route
							path={RouteEnums.VARIANT_CATEGORIES}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={CategoryVariant} />
								</Suspense>
							}
						/>
						{/* Order Management */}
						<Route
							path={RouteEnums.ORDER_MANAGEMENT}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={OrdermanagementPage} />
								</Suspense>
							}
						/>
						{/* onboardingApproval */}
						<Route
							path={RouteEnums.ONBOARDING_APPROVAL}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={onboardingApproval} />
								</Suspense>
							}
						/>
						{/* UpdateApproval */}
						<Route
							path={RouteEnums.UPDATE_APPROVAL}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={UpdateApproval} />
								</Suspense>
							}
						/>
						{/* Create Product */}
						<Route
							path={RouteEnums.PRODUCTCREATE}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={ProductFormPage} />
								</Suspense>
							}
						/>
						{/* Edit Product */}
						<Route
							path={RouteEnums.PRODUCTEDIT}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={EditProduct} />
								</Suspense>
							}
						/>
						{/* Services */}
						<Route
							path={RouteEnums.TelehealthCreate}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={ServiceFormPage} />
								</Suspense>
							}
						/>
						{/* List Variant */}
						<Route
							path={RouteEnums.VARIANTS}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={Variant} />
								</Suspense>
							}
						/>
						{/* Create Variant */}
						<Route
							path={RouteEnums.VARIANTS_CREATE}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={VariantForm} />
								</Suspense>
							}
						/>
						{/* Edit Variant */}
						<Route
							path={RouteEnums.VARIANTS_EDIT}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={EditVariant} />
								</Suspense>
							}
						/>
						{/* Invoice Order */}
						<Route
							path={RouteEnums.ORDER_DETAILS}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={InvoiceOrder} />
								</Suspense>
							}
						/>
						{/* cart product detail */}
						<Route
							path={RouteEnums.CART_DETAILS}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={CartProductDetail} />
								</Suspense>
							}
						/>
						{/* Categories */}
						<Route
							path={RouteEnums.Categories}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={Categories} />
								</Suspense>
							}
						/>
						{/* Create Category */}
						<Route
							path={RouteEnums.CreateCategory}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={CreateCategory} />
								</Suspense>
							}
						/>
						{/* Edit Category */}
						<Route
							path={RouteEnums.EditCategory}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={EditCategory} />
								</Suspense>
							}
						/>
						{/* Tag List */}
						<Route
							path={RouteEnums.Tags}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={Tags} />
								</Suspense>
							}
						/>
						{/* Create Tag  */}
						<Route
							path={RouteEnums.CreateTag}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={CreateTag} />
								</Suspense>
							}
						/>
						{/* Edit Tag */}
						<Route
							path={RouteEnums.EditTag}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={EditTag} />
								</Suspense>
							}
						/>
						{/* Posts list */}
						<Route
							path={RouteEnums.GetAllPosts}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={Posts} />
								</Suspense>
							}
						/>
						{/* Post preview */}
						<Route
							path={RouteEnums.PreviewPosts}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={PreviewPost} />
								</Suspense>
							}
						/>
						{/* Edit Post */}
						<Route
							path={RouteEnums.EditPostDetails}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={EditPost} />
								</Suspense>
							}
						/>
						{/* Create Post */}
						<Route
							path={RouteEnums.CreatePost}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={CreatePost} />
								</Suspense>
							}
						/>
						{/* PostPriority list */}
						<Route
							path={RouteEnums.PostPriority}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={PostPriority} />
								</Suspense>
							}
						/>
						{/* Post priority create */}
						<Route
							path={RouteEnums.CreatePostPriority}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={CreatePostPriority} />
								</Suspense>
							}
						/>
						{/* Post priority edit */}
						<Route
							path={RouteEnums.EditPostPriority}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={EditPostPriority} />
								</Suspense>
							}
						/>
						{/* Post Comment's approval list */}
						<Route
							path={RouteEnums.PostCommentApproval}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={PostComments} />
								</Suspense>
							}
						/>
						{/* Users List */}
						<Route
							path={RouteEnums.Users}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={Users} />
								</Suspense>
							}
						/>
						{/* Create User */}
						<Route
							path={RouteEnums.CreateUser}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={CreateUser} />
								</Suspense>
							}
						/>
						{/* Edit User */}
						<Route
							path={RouteEnums.EditUser}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={EditUser} />
								</Suspense>
							}
						/>
						{/* SEO Routes */}
						{/* SEO content list */}
						<Route
							path={RouteEnums.SEOContentList}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={SeoContentList} />
								</Suspense>
							}
						/>
						{/* SEO Content edit */}
						<Route
							path={RouteEnums.SEOContentEdit}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={EditSEOContent} />
								</Suspense>
							}
						/>
						{/* SEO redirect url list */}
						<Route
							path={RouteEnums.SEORedirectList}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={SeoRedirectList} />
								</Suspense>
							}
						/>
						{/* Create SEO redirection */}
						<Route
							path={RouteEnums.CreateSEORedirection}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={CreateSeoRedirection} />
								</Suspense>
							}
						/>
						{/* Update SEO redirection */}
						<Route
							path={RouteEnums.SEORedirectContentEdit}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={EditSeoUrlRedirection} />
								</Suspense>
							}
						/>
						{/* Media gallery */}
						<Route
							path={RouteEnums.GalleryList}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={MediaGallery} />
								</Suspense>
							}
						/>
						{/* Upload media */}
						<Route
							path={RouteEnums.UploadMedia}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={UploadMedia} />
								</Suspense>
							}
						/>

						{/* Edit media */}
						<Route
							path={RouteEnums.UpdateMediaFile}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={UpdateMediaFile} />
								</Suspense>
							}
						/>
						{/* Admin Utilities */}
						<Route
							index
							path={RouteEnums.AdminUtilities}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={Utilities} />
								</Suspense>
							}
						/>
						{/* Roles grid */}
						<Route
							index
							path={RouteEnums.Roles}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={Roles} />
								</Suspense>
							}
						/>
						{/* Create Role */}
						<Route
							index
							path={RouteEnums.CreateRoles}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={CreateRole} />
								</Suspense>
							}
						/>
						{/* Edit Role */}
						<Route
							index
							path={RouteEnums.RoleEditRoute}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={EditRole} />
								</Suspense>
							}
						/>
						{/* Access routes of role */}
						<Route
							index
							path={RouteEnums.AccessRoutesOfRole}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={RoleDetails} />
								</Suspense>
							}
						/>
						{/* Not found */}
						<Route
							index
							path={RouteEnums.NotFound}
							element={
								<Suspense fallback={<Loader />}>
									<NotFound />
								</Suspense>
							}
						/>
						{/* Redirect any unknown route to Not Found */}
						<Route path='*' element={<Navigate to={RouteEnums.NotFound} />} />
						{/* Appointments */}
						<Route
							path={RouteEnums.Appointments}
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoute component={Appointments} />
								</Suspense>
							}
						/>
					</Routes>
				</AdminLayout>
			</HelmetProvider>
		</Provider>
	)
}

export default App
