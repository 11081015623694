import {createSlice, PayloadAction, createAsyncThunk, isAction, createAction } from "@reduxjs/toolkit"
import *  as prodTypes  from "../types/ProductTypes"
import Fetcher from "../utils/Fetcher";
import { category_sku_type } from "./productReducer";
import { skuToNameConverter } from "../utils/SplitSku";
export interface  editProductInitialState {
     product: prodTypes.EditProductTypes | null,
     productImages: prodTypes.ImageMap[],
     productStocks: prodTypes.ProductStock[],
     fetchStatus: string,
     variant_sku_map: Array<category_sku_type> | null,
     error: string | null
}
const initialState: editProductInitialState = {
    product: null,
    fetchStatus: 'idle',
    productImages: [],
    productStocks: [],
    variant_sku_map: [],
    error: null
}
function addBaseUrlToImages(
  productImageMap: prodTypes.ImageMap[],
  baseUrl: string
): prodTypes.ImageMap[] {
  if (!Array.isArray(productImageMap)) {
    return [];
  }

  return productImageMap.map((imageMap) => {
    if (imageMap.images && Array.isArray(imageMap.images)) {
      const imagesWithBaseUrl = imageMap.images.map((image) => {
        if (image.image_key) {
          return { ...image, url: baseUrl + image.image_key + image.id };
        }
        return image;
      });

      return { ...imageMap, images: imagesWithBaseUrl };
    }

    return imageMap;
  });
}
export const fetchProductData = createAsyncThunk(
  'editProduct/fetchProductData',  // action type string
  async (productId: string, thunkAPI) => {
    const response = await Fetcher(`/catalouge/item/${productId}`);
    // Handle error (optional)
    if (response["error"]) {
      return thunkAPI.rejectWithValue('Failed to fetch product data');
    }
    const data = await response["product"];
    return data;  // this becomes the fulfilled action payload
  }
);
export const productEditSlice = createSlice({
     name: 'editProduct',
     initialState, 
     reducers: {
        setProductInformation: (state, action:PayloadAction<prodTypes.EditProductTypes>) => {
             state.product = action.payload
        },
        setProductVariants: (state, action: PayloadAction<prodTypes.ProductVariant[]>) => {
               state.product!.product_variants = action.payload;
        },
        addStockSkuSelector: (state, action: PayloadAction<string>) => {
             state.stockSkuSelector = [...state.stockSkuSelector, ...action.payload]
        },
        setProductImageForm: (state, action: PayloadAction<prodTypes.ImageMap[]>) => {
              state.productImages = action.payload
        },
        resetProductStocks: (state) => {
           state.productStocks = []
        },
        resetVariantSkuMap: (state) => {
           state.variant_sku_map = []
        },
        resetProductImages: (state) => {
          state.productImages = []
        }
     },
     extraReducers: (builder) => {
           builder.addCase(fetchProductData.pending, (state) => {
               state.fetchStatus = "pending"
               state.error = null
           }).addCase(fetchProductData.fulfilled, (state, action) => {
               state.product = action.payload
               // lets as also parse the product Images and stocks from the list
               var image_array = []
               state.product?.product_variants?.forEach((value) => {
                   var image_map = {}
                   state.productStocks.push(...value.productStocks!)
                   // lets map the images with the sku code
                   image_map["sku_code"] = value.sku!;
                   image_map["images"] = value.images
                   image_array.push(image_map)
                   // lets also map the sku to the sku name for editing purpose
                   var sku_string = skuToNameConverter(state.product?.groupedVariants as prodTypes.GroupedVariants[], value.sku as string, state.product?.sku as string)
                   // lets then update the sku list
                   state.variant_sku_map = [...state.variant_sku_map!, {"label":sku_string, "sku": value.sku!}]
                   
               })
               state.productImages = image_array
               state.fetchStatus = "succeeded"
           }).addCase(fetchProductData.rejected, (state, action) => {
               state.fetchStatus = "failed"
               state.error = action.payload || "Failed to fetch error"
           })
           builder.addCase(resetState, () => initialState);
     }
})
export const resetState = createAction('product/reset');
export default productEditSlice.reducer
export const {resetProductStocks, resetProductImages, setProductImageForm, setProductVariants, addStockSkuSelector, resetVariantSkuMap} = productEditSlice.actions